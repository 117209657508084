/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable wrap-rem-on-px/wrap-rem-on-px */
/* stylelint-enable wrap-rem-on-px/wrap-rem-on-px */
/*
USAGE:
font-size: fluid(12, 62); : from rem(12)@375px to  rem(62)@1440px
gap: fluid(10, 33, 992);  : from rem(10)@991px to  rem(33)@1440px
margin-right: fluid(32, 65, 320, 2560);  : from rem(32)@320px to  rem(65)@2560px
*/
/* stylelint-disable */
/* stylelint-enable */
/* Font */
/* Base palette */
/* Brand palette */
/* Theme */
/* State palette */
.qpac.hero header {
  padding: 1.25rem 0 6rem;
}
@media screen and (min-width:1199px) {
  .qpac.hero header {
    max-width: 46.9375rem;
    margin-left: auto;
  }
}
.qpac.hero header .h1 {
  border: none;
  color: #DCDCDC;
  font-size: clamp(2.625rem, 1.31579vw + 2.315789375rem, 3.5rem);
  font-weight: 400;
  letter-spacing: -0.035rem;
  line-height: 1;
  text-transform: uppercase;
}
.qpac.hero header p {
  max-width: 24.6875rem;
  padding-top: 1rem;
  font-size: 1.0625rem;
  font-weight: 300;
  letter-spacing: -0.031875rem;
  line-height: 1.35;
  opacity: 0.9;
}
.qpac.hero .hero__wrapper {
  position: relative;
}
.qpac.hero .aspect-box {
  --aspect-ratio: 375/375;
}
@media screen and (min-width:768px) {
  .qpac.hero .aspect-box {
    --aspect-ratio: 787/1400;
  }
}
.qpac.hero .aspect-box .hero__banner {
  width: 100%;
  height: 100%;
}
.qpac.hero .hero__control, .qpac.hero .video__control {
  position: absolute;
  bottom: clamp(1rem, 0.37594vw + 0.911654375rem, 1.25rem);
  left: clamp(1rem, 0.46992vw + 0.8895675rem, 1.3125rem);
  display: flex;
  min-width: 14.75rem;
  align-items: center;
  padding: 0.875rem 1.3125rem 0.8125rem;
  -webkit-backdrop-filter: blur(10);
          backdrop-filter: blur(10);
  background-color: #fff;
  gap: 0.625rem;
}
@media screen and (min-width:991px) {
  .qpac.hero .hero__control, .qpac.hero .video__control {
    border-radius: 0.375rem;
  }
}
.qpac.hero .hero__control .video__button, .qpac.hero .video__control .video__button {
  display: flex;
  width: 2rem;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
  background-color: #00FFAE;
}
.qpac.hero .hero__control .video__button span, .qpac.hero .video__control .video__button span {
  font-size: 0;
}
.qpac.hero .hero__control .video__button span.video__pause, .qpac.hero .video__control .video__button span.video__pause {
  display: none;
}
.qpac.hero .hero__control .video__button span svg, .qpac.hero .video__control .video__button span svg {
  position: relative;
  display: block;
  width: 0.5rem;
  height: 0.625rem;
  transform: scale(1);
  will-change: transform;
  transition: all 0.3s ease-in-out;
}
.qpac.hero .hero__control .video__button:hover span svg, .qpac.hero .video__control .video__button:hover span svg {
  transform: scale(1.2);
}
.qpac.hero .hero__control .video__details p, .qpac.hero .hero__control .video__details span, .qpac.hero .hero__control .video__details time, .qpac.hero .video__control .video__details p, .qpac.hero .video__control .video__details span, .qpac.hero .video__control .video__details time {
  color: #242830;
  font-size: 0.6875rem;
  font-weight: 400;
  letter-spacing: 0.020625rem;
  line-height: 0.9;
  text-transform: uppercase;
}
.qpac.hero .hero__control .video__details time, .qpac.hero .hero__control .video__details time + span, .qpac.hero .video__control .video__details time, .qpac.hero .video__control .video__details time + span {
  display: inline;
  opacity: 0.5;
}
.qpac.hero--home header {
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (min-width:1199px) {
  .qpac.hero--home header {
    max-width: unset;
    padding-top: clamp(0.125rem, 4.88722vw - 1.02349625rem, 3.375rem);
  }
}
.qpac.hero--home header .h1 {
  border-top: 0.0625rem solid rgba(220, 220, 220, 0.3);
  margin-bottom: 0;
  color: #DCDCDC;
  font-size: clamp(2.125rem, 4.88722vw + 0.97650375rem, 5.375rem);
  font-weight: 400;
  letter-spacing: -0.1075rem;
  line-height: 109%;
  text-transform: uppercase;
}
@media screen and (min-width:991px) {
  .qpac.hero--home header .h1 {
    font-weight: 300;
    line-height: 100%;
  }
}
.qpac.hero--home header .h1 span {
  display: block;
  padding: clamp(0.0625rem, 0.09398vw + 0.04041375rem, 0.125rem) clamp(1rem, 0.37594vw + 0.911654375rem, 1.25rem) clamp(0.0625rem, 0.37594vw - 0.025845625rem, 0.3125rem);
  border-bottom: 0.0625rem solid rgba(220, 220, 220, 0.3);
}
.qpac.hero--home header .h1 span + span {
  color: #00FFAE;
}
.qpac.hero--home header blockquote {
  position: relative;
  max-width: 40.0625rem;
  padding: clamp(1.0625rem, 0.09398vw + 1.04041375rem, 1.125rem) clamp(1rem, 4.41729vw - 0.03806375rem, 3.9375rem) 1.6875rem;
  color: #fff;
  font-size: 1.0625rem;
  font-weight: 300;
  letter-spacing: -0.031875rem;
  line-height: 1.35;
}
@media screen and (min-width:991px) {
  .qpac.hero--home header blockquote::before {
    position: absolute;
    top: 1.0625rem;
    left: 1.3125rem;
    content: "//";
    font-size: 1.0625rem;
    font-weight: 300;
    letter-spacing: -0.031875rem;
    line-height: 1.35;
  }
}
.qpac.hero--home .hero__wrapper {
  padding-bottom: 0;
}
@media screen and (min-width:768px) {
  .qpac.hero--home figure {
    --aspect-ratio: 838/1440;
  }
}
@media screen and (min-width:1199px) {
  .qpac.hero--webinar header {
    display: flex;
    max-width: unset;
    justify-content: space-between;
    padding-bottom: 9.8125rem;
  }
}
.qpac.hero--webinar header .hero__date {
  padding-top: 0;
  margin-bottom: 0.375rem;
  color: #8F9091;
  font-size: 0.8125rem;
  font-weight: 400;
  letter-spacing: unset;
  line-height: 120%;
  text-transform: uppercase;
}
.qpac.hero--webinar header .hero__banner-title {
  max-width: 46.9375rem;
}
.qpac.hero--webinar iframe {
  border: none;
}